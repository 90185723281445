<template>
  <div v-if="$props.url != null">
    <embed :src="$props.url" width="100%" height="1200" />
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
    },
  },
};
</script>
