<template>
  <v-row class="wrapper">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="#1d2b58"
        style="color: white; margin-left: 10px"
        >Choose file <v-icon>mdi-file</v-icon></v-btn
      >
    </template>

    <v-card style="width:100%">
      <v-card-title class="text-h5 grey lighten-2">
        Choose File
      </v-card-title>

      <v-card-text>
        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
          <v-tab> Upload File </v-tab>
          <v-tab> File List </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <v-file-input
                  accept="image/*"
                  label="Choose Files"
                  chips
                  multiple
                  prepend-icon=""
                  v-bind="basic"
                  v-model="data.file"
                ></v-file-input>
              </v-card-text>
              <v-btn
                block
                color="#1D2B58"
                style="color: white"
                v-on:click="uploadFile"
                >Upload Files</v-btn
              >
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-table
              :items="files"
              :sn="false"
              :only="['name']"
              :headingTransformer="headingTransformer"
              :additionalColumns="['Action']"
              :additionalColumnsTransformer="additionalColumnsTransformer"
              :html="['Action']"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider />
      <div class="d-flex justify-center pt-2 pb-1">
        <v-btn
          color="primary"
          elevation="2"
          v-on:click="submit"
          :loading="loading"
          >Submit</v-btn
        >
      </div>
    </v-card>
  </v-row>
</template>
<script>
import util from "@/util/util.js";
import action from "./delete-file-button.vue";
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
    },
  },
  mounted() {
    this.files = this.$props.item.files;
    this.data.file = this.$props.item.files;
    util.event.$on("file-deleted", (file) => {
      this.files = this.files.filter(function(value) {
        return value != file;
      });
      this.data.file = this.data.file.filter(function(value) {
        return value.name != file.name;
      });
    });
  },
  data() {
    return {
      tab: 0,
      basic: util.input.basic,
      files: [],
      data: { file: [] },
      loading: false,
    };
  },
  methods: {
    clicked(fun, id) {
      fun();
      this.$emit("input", id);
    },
    uploadFile() {
      let formData = new FormData();
      let i = 0;
      this.data.file.map((f) => {
        formData.append(`file[${i++}]`, f);
      });
      util
        .http({
          headers: {
            "Content-Type": "multipart/form-data",
          },
          url: this.$props.name + "/file-upload",
          method: "post",
          data: formData,
        })
        .then((res) => {
          if (res.data.status == 1) {
            util.notify(1, "Successfully Uploaded");
            res.data.data.forEach((d) => {
              this.files.push(d);
            });
          }
        });
    },
    submit() {
      this.loading = true;
      let form = {};
      form.file = this.files;
      util
        .http({
          url: this.$props.name + "/file-update/" + this.$props.item.id,
          method: "post",
          data: form,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            util.notify(1, "Updated");
            this.$emit("closeDialog", true);
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
    headingTransformer: function(val) {
      return util.camelCase(val);
    },
    additionalColumnsTransformer() {
      return {
        Action: (row) => {
          return [
            {
              comp: action,
              prop: {
                file: { row },
                name: this.$props.name,
              },
            },
          ];
        },
      };
    },
  },
  watch: {
    "$props.item": {
      handler: function() {
        this.files = this.$props.item.files;
        this.data.file = this.$props.item.files;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.wrapper {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
