<template>
  <div style="padding: 1rem">
    <h2>Approve Quotation</h2>

    <div class="mt-5 mb-5">
      <v-row>
        <v-col cols="10">
          <span>{{ $props.item.customer.full_name }}</span>

          <div class="d-flex  justify-space-between">
            <span class="">Quotation Id</span>
            <span>{{ $props.item.id }}</span>
          </div>
          <div class="d-flex  justify-space-between">
            <span class="">Ref Number</span>
            <span>{{ $props.item.ref_no }}</span>
          </div></v-col
        >
      </v-row>
    </div>
    <div>
      <h4 class="mb-3">Products</h4>
      <v-form>
        <v-row>
          <v-col
            cols="12"
            v-for="p in allProducts"
            :key="p.id"
            style="padding:0"
          >
            <v-checkbox
              v-bind="basic"
              v-model="form.products"
              :label="p.name"
              :value="p.id"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn
              color="primary"
              elevation="2"
              v-on:click="submit"
              :loading="loading"
            >
              Approve
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.fetchProducts();
  },
  data() {
    return {
      loading: false,
      basic: util.input.basic,
      form: { products: [] },
      allProducts: [],
    };
  },
  methods: {
    fetchProducts() {
      util
        .http({
          url: "product/get-all",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            let ids = this.$props.item.products.map((i) => {
              return i.product_id;
            });
            this.allProducts = res.data.data.filter((p) => {
              if (ids.includes(p.id)) {
                return p;
              }
            });
          }
        });
    },
    submit() {
      this.loading = true;
      util
        .http({
          url: "quotation/approve/" + this.$props.item.id,
          method: "post",
          data: this.form,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            util.notify(1, "Quotation Approved");
            this.$emit("closeDialog", true);
          }
        });
    },
  },
  watch: {
    "$props.item": {
      handler: function() {
        this.fetchProducts();
      },
      deep: true,
    },
  },
};
</script>
