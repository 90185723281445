<template>
  <div style="padding: 1rem">
    <h2>Terms And Conditions</h2>
    <v-form style="padding: 1rem 0">
      <v-row>
        <v-col cols="12">
          <v-textarea
            label="Terms and Conditions"
            v-bind="basic"
            v-model="data.terms"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col>
          <v-btn class="primary" @click="onSubmit()" :loading="loading"
            >Submit</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data: function() {
    return {
      loading: false,
      rules: util.validation,
      basic: util.input.basic,
      data: { terms: "" },
    };
  },
  mounted() {
    this.data.terms = this.$props.item.terms;
  },
  methods: {
    onSubmit() {
      this.loading = true;
      util
        .http({
          url: "quotation/add-terms/" + this.$props.item.id,
          method: "put",
          data: this.data,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            util.notify(1, "Terms Added");
            this.data = {};
            this.$emit("closeDialog", true);
          }
        });
    },
  },
};
</script>
